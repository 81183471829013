require("./bootstrap");

import "datatables.net-bs4";
import "datatables.net-fixedheader-dt";
import 'datatables.net-colreorder-dt';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "bootstrap";

import * as Cascade from "./select2cascade";
import * as koco from "./ko-co";

import { Chartisan, ChartisanHooks } from "@chartisan/chartjs";
import { JSONEditor as NewJSONEditor, jmespathQueryLanguage, lodashQueryLanguage } from 'vanilla-jsoneditor';
import { parse, stringify } from 'lossless-json';
import tippy, {animateFill, followCursor}  from 'tippy.js';

import $ from "jquery";
import AirDatepicker from 'air-datepicker';
import Alpine from "alpinejs";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ClipboardJS from "clipboard";
import ColorSchemesPlugin from "chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes";
import Eeaas from "eeaas";
import JQueryDataTables from "datatables.net";
import Nyancat from "eeaas-nyancat";
import { PastelTwo8 } from "chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer";
import Select2Plugin from "select2";
import Snake from "eeaas-snake";
import Toastify from 'toastify-js'
import anime from 'animejs';
import {createPopper} from '@popperjs/core';
import localeEn from 'air-datepicker/locale/en';

const LosslessJSONParser = { parse, stringify }

function isJsonString(str) {
    try {
        parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

$.fn.extend({
	toggleText: function(a, b){
		return this.text(this.text() == b ? a : b);
	}
});
$.select2 = Select2Plugin;
$.DataTables = JQueryDataTables;
$.fn.extend({
    /* eslint-disable */
	autoHeight: function () {
        /* eslint-enable */
		function autoHeight_(element) {
			return jQuery(element)
			.css({ "height": 0, "overflow-y": "hidden" })
			.height(element.scrollHeight);
		}
		return this.each(function() {
			autoHeight_(this).on("input", function() {
			autoHeight_(this);
			});
		});
	}
});
$.each(["put", "delete", "head", "patch"], function(_i, method) {
	$[method] = function(url, data, success, dataType) {
		if (typeof data === "function") {
			dataType = dataType || success;
			success = data;
			data = undefined;
		}
		return $.ajax({
				url: url,
				type: method,
				dataType: dataType,
				data: data,
				success: success
			});
	};
});

window.navigationBarSubmit = function() {
	event.preventDefault();
    this.closest('form').submit();
};

window.Alpine = Alpine;

window.Select2Cascade = Cascade.Select2Cascade;
window.Toastify = Toastify;
window.$ = window.jQuery = $;


window.console._notset = window.console.log;
window.console._critical = window.console.error;
window.console.log = function () { return window.console._intercept('notset', arguments) };
window.console.critical = function () { return window.console._intercept('critical', arguments) };

['trace', 'info', 'warn', 'error', 'debug'].forEach(function(method) {
		window.console['_' + method] = window.console[method];
		window.console[method] = function() {
			return window.console._intercept(method, arguments);
		};
	});
window.console.warning = window.console.warn
window.console._intercept = function (type, args) {
	window.console._collect(type, args);
	if(window.console.log_level <= window.console._levels[type.toUpperCase()]) {
		if (typeof args[0] === 'string') {
			args[0] = window.console._color_levels[type.toUpperCase()] + args[0] + `\x1b[0m`;
		}
		return window.console['_'+type](...args);
	}
}
window.console._levels = {
	'NOTSET': 0,
	'TRACE': 5,
	'DEBUG': 10,
	'INFO': 20,
	'WARN': 30,
	'ERROR': 40,
	'CRITICAL': 50
};
window.console._color_levels = {
	'NOTSET': `\x1b[0m`,
	'TRACE': `\x1b[36m`,
	'DEBUG': `\x1b[34m`,
	'INFO': `\x1b[32m`,
	'WARN': `\x1b[33m`,
	'ERROR': `\x1b[31m`,
	'CRITICAL': `\x1b[5m\x1b[31m\x1b[4m\x1b[1m`
};
window.console.history = [];
window.console._collect = function (type, args) {
	var time = new Date().toUTCString();
	if (!type) type = 'log';
	window.console.history.push({ type: type, timestamp: time, arguments: args});
}



let tip;
koco.addSupportForTheKonamiCode({});
Eeaas.register(Nyancat);
Eeaas.register(Snake);
window.jQuery(function () {
	Alpine.start();
	function egg() {
		import("easter-egg-collection");
		console.log("The Konami Code has been entered.");
		Eeaas.enable();
	}
	$(document).on("konamicode", egg);

	$.ajaxSetup({
		headers: {
			"X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
			Authorization:
				"Bearer " + $("meta[name=api-token]").attr("content"),
			Accept: "application/json",
		},
	});
	const select2 = $(".select2");
	if (select2.length > 0) {
		select2.each(function () {
			$(this).select2();
			$(this).on("change", function (e) {
				if(typeof $(this).attr("multiple") === 'undefined'){
					window.livewire.emit($(this).attr("id"), e.target.value);
				}else{
					window.livewire.emit($(this).data('emit-to'), [$(this).select2('val'), $(this).data('emit-more')]);
				}
			});
		});
		const select2reset = function () {
			$(".select2").select2().val(null).trigger("change");
		};
		$(document).on("reset", select2reset);
		$(document).on("select2reset", select2reset);
	}
	const select2cascade = $(".select2cascade");
	if (select2cascade.length > 0) {
		select2cascade.each(function () {
			const options = { width: "resolve" };
			new window.Select2Cascade(
				$("#" + $(this).data("depends")),
				$(this),
				$(this).data("url"),
				options
			);
		});
	}
	$(document).on("reload", function () {
		location.reload();
	});
	const datatable = $("table.datatable");
	if (!location.pathname.startsWith("/rolesgui")) {
		if (datatable.length > 0) {
			datatable.each(function () {
				let $this = $(this);
				let table = $this.DataTable({
					dom: "Bfrtip",
					fixedHeader: {
						header: true,
						footer: true
					},
					colReorder: true,
					stateSave: true,
					paging: false,
					buttons: ["print", "csvHtml5"],

				});
				let order = $this.data("table-order");
				if (order !== undefined || order != null) {
					let order_desc = $this.data("table-desc");
					if (order_desc !== undefined || order_desc != null) {
						if (
							typeof order_desc === "string" ||
							order_desc instanceof String
						) {
							order_desc = order_desc === "true";
						}
						if (order_desc) {
							order_desc = "desc";
						} else {
							order_desc = "asc";
						}
					} else {
						order_desc = "desc";
					}
					let order_out;
					if (
						typeof order === "string" ||
						order instanceof String
					) {
						let order_lst = order.split(',');
						order_out = [];
						order_lst.forEach (function (element) {
								let elements = element.split('-')
								if (elements.length === 1) {
									order_out.push([parseInt(elements[0]), order_desc]);
								} else {
									let this_order_desc;
									if (!order_desc === "desc") {
										this_order_desc = "desc";
									} else {
										this_order_desc = "asc";
									}
									order_out.push([parseInt(elements[0]), this_order_desc]);
								}

							} )
					}else {
						order_out = [order, order_desc];
					}
					table.order(order_out).draw();
				}
				$this.removeAttr("hidden");
				table.fixedHeader.enable()
				table.colReorder.enable();
			});
		}
	}
	const all_charts = $(".chart");
	if (all_charts.length > 0) {
		Chart.plugins.register(ChartDataLabels);
		Chart.plugins.register(ColorSchemesPlugin);
		Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
			align: "end",
			anchor: "end",
			font: {
				weight: "bold",
			},
		});
		all_charts.each(function () {
			const chart = new Chartisan({
				chart: Chart,
				el: "#" + $(this).attr("id"),
				url: $(this).data("url"),
				hooks: new ChartisanHooks()
					.options({
						type: "horizontalBar",
						options: {
							legend: {
								labels: {
									fontSize: 20,
								},
							},
							plugins: {
								colorschemes: {
									scheme: PastelTwo8,
								},
							},
						},
					})
					.beginAtZero(true),
			});
			chart.update({ background: true });
		});
	}
    let copy = $(".copy");
	if (copy.length > 0) {
		if (copy.length > 0) {
			new ClipboardJS(".copy");
		}
	}
	if ($(".postAdded").length > 0) {
		setInterval(function () {
			window.livewire.emit("postAdded");
		}, 300000);
	}

	function addandremove(a) {
		if (a === window.urlid) {
			const el = $("#url_div_REPLACE_ME").clone();
			let rawHtml = el.prop("outerHTML");
			rawHtml = rawHtml.replaceAll("REPLACE_ME", window.urlid + 1);
			rawHtml = rawHtml.replace("hidden", "");
			window.urlid = window.urlid + 1;
			$("#urlid_button_" + a.toString()).text("Remove");
			$(".url_inputs_div").append(rawHtml);
		} else {
			$("#url_div_" + a.toString()).remove();
			$(".url_inputs_div").trigger("change");
		}
	}
	if (location.pathname === "/newjob") {
		window.urlid = 0;
		$(".url_inputs_div").on("change", function () {
			let urls = window._.uniq(
				window._.filter(
					$(".url-input:visible")
						.map(function () {
							return $(this).val();
						})
						.get(),
					function (item) {
						return (
							item !== "" &&
							/((^((http[s]):\/)?\/?(www\.linkedin\.com\/search\/results\/people\/)(.*\S)$)|(^((http[s]):\/)?\/?(www\.linkedin\.com\/groups\/)(.*\S)$))/.test(
								item
							)
						);
					}
				)
			).join('{)(}');
			window.livewire.emit("url_list", urls);
		});
		$(document).on("reset", function () {
			window.urlid = 0;
			$(".url_div").not("#url_div_REPLACE_ME").remove();
			$("#urlid_button_0").text("ADD");
			$('#url_0').val('');
		});
		window.addandremove = addandremove;
	}

	function addandremoveforblacklist(a) {
		if (a === window.nameid) {
			const el = $("#name_div_REPLACE_ME").clone();
			let rawHtml = el.prop("outerHTML");
			rawHtml = rawHtml.replaceAll("REPLACE_ME", window.nameid + 1);
			rawHtml = rawHtml.replace("hidden", "");
			window.nameid = window.nameid + 1;
			$("#nameid_button_" + a.toString()).text("Remove");
			$(".name_inputs_div").append(rawHtml);
		} else {
			$("#name_div_" + a.toString()).remove();
			$(".name_inputs_div").trigger("change");
		}
	}
	if (location.pathname === "/blacklist") {
		window.nameid = 0;
		$(".name_inputs_div").on("change", function () {
			let names = window._.uniq(
				window._.filter(
					$(".name-input:visible")
						.map(function () {
							return $(this).val();
						})
						.get(),
					function (item) {
						return item !== "";
					}
				)
			).join('{)(}');
			window.livewire.emit("name_list", names);
		});
		$(document).on("reset", function () {
			window.nameid = 0;
			$(".name_div").not("#name_div_REPLACE_ME").remove();
			$("#nameid_button_0").text("ADD");
			$('#name_0').val('');
		});
		window.addandremoveforblacklist = addandremoveforblacklist;
	}

	function settup_tippy(){
		return tippy('[data-tippy]',{
			content: (reference) => reference.getAttribute('data-tippy'),
			animation: 'scale',
			followCursor: 'initial',
			arrow: false,
            allowHTML: true,
			animateFill: true,
			theme: 'material',
			plugins: [animateFill, followCursor],
			interactiveDebounce: 750000000,
			interactive: true,
			appendTo: (reference) => reference.closest('.tippy-append'),
		});
	}
	tip = settup_tippy();
    $(document).on("tippy_reset", function () {
		$.each(tip, function () {
			this.destroy();
		});
		tip = settup_tippy();
	});

	const jsoneditor = $('.jsoneditor');
	if(jsoneditor.length > 0){
		window.NewJSONEditor = NewJSONEditor;
		jsoneditor.each(function (_i, htmlelment) {
			const editor = new NewJSONEditor({
				target: htmlelment,
				props: {
					/* eslint-disable no-unused-vars */
					onChange: (_updatedContent, _previousContent, { _contentErrors, patchResult }) => {
						/* eslint-enable no-unused-vars */
						if (typeof $(this).data('emit_to') !== 'undefined') {
							if(isJsonString(_updatedContent.text) || isJsonString(stringify(patchResult.json))){
								if (patchResult === null){
									window.livewire.emit($(this).data('emit_to'), stringify(parse(_updatedContent.text)), $(this).data('key'), $(this).data('column'), $(this).data('rowid'));
								}else{
									window.livewire.emit($(this).data('emit_to'), stringify(patchResult.json), $(this).data('key'), $(this).data('column'), $(this).data('rowid'));
								}
							}
							
						}
					},
					sortObjectKeys: true,
					readOnly: $(this).data("mode") === 'view',
					navigationBar: $(this).data("mode") !== 'view',
					mainMenuBar: $(this).data("mode") !== 'view',
					mode: 'tree',
					parser: LosslessJSONParser,
					queryLanguages: [lodashQueryLanguage, jmespathQueryLanguage],
					/* eslint-disable no-unused-vars */
					onRenderMenu: (items, context)=>{
						/* eslint-enable no-unused-vars */
						return items.filter(v => v.text !== "table" && v.type === "button");
					}
				}
			});
			const initialJson = {json: $(this).data("json")}
			editor.set(initialJson);
		});
	}
	$('start--hidden').removeAttr("hidden");
	if($('#impersonate-ui').length > 0){
		window.toggleFull = function() {
			$('#impersonate-ui-fullcontainer').toggleClass('show');
		};

		$('#impersonate-ui-icon, #impersonate-ui-close').on('click', window.toggleFull);
	}

	if($('.postAdded').length){
		const $searchInput = $('#search');
		let typingTimer;

		$searchInput.on('keyup', function() {
			clearTimeout(typingTimer);
			typingTimer = setTimeout(function () {
				const searchQuery = $('#search').val().toLowerCase();
				$('.post_i').each(function() {
					const card = $(this);
					const cardSearchData = card.data('search').toLowerCase();
					card.toggleClass('is-hidden', !cardSearchData.includes(searchQuery));
				});
			}, 500);
		});


	}
	if($('#lifetime').length){
		const today=new Date();
		new AirDatepicker('#lifetime', {
			locale: localeEn,
			minDate: today,
			maxDate: new Date().setDate(today.getDate() + 364),
			position({$datepicker, $target, $pointer, isViewChange, done}) {
				let popper = createPopper($target, $datepicker, {
					placement: 'bottom',
					onFirstUpdate: function () {
						!isViewChange && anime.remove($datepicker);
		
						$datepicker.style.transformOrigin = 'center top';
		
						!isViewChange && anime({
							targets: $datepicker,
							opacity: [0, 1],
							rotateX: [-90, 0],
							easing: 'spring(1.3, 80, 5, 0)',
						})
		
					},
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 10]
							}
						},
						{
							name: 'arrow',
							options: {
								element: $pointer,
							}
						},
						{
							name: 'computeStyles',
							options: {
								gpuAcceleration: false,
							},
						},
					]
				});
		
				return () => {
					anime({
						targets: $datepicker,
						opacity: 0,
						rotateX: -90,
						duration: 300,
						easing: 'easeOutCubic'
					}).finished.then(() => {
						popper.destroy();
						done();
					})
				}
			},
			onSelect({date}) {
				const one_day=1000*60*60*24;
				let days = Math.ceil((date.getTime()-today.getTime())/(one_day)) + 1
				window.livewire.emit('lifetime', days);
				$('#lifetime').val(days);
			}
		})
	}
});
	