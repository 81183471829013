/* eslint-disable no-unused-vars */
import $ from 'jquery';
window.$ = window.jQuery = $;
/**
 * A Javascript module to loadeding/refreshing options of a select2 list box using ajax based on selection of another select2 list box.
 *
 * @url : https://gist.github.com/ajaxray/187e7c9a00666a7ffff52a8a69b8bf31
 * @auther : Anis Uddin Ahmad <anis.programmer@gmail.com>
 *
 * Live demo - https://codepen.io/ajaxray/full/oBPbQe/
 * w: http://ajaxray.com | t: @ajaxray
 */
export var Select2Cascade = ( function(window, $) {

    function Select2Cascade(parent, child, url, select2Options) {
        let afterActions = [];
        const options = select2Options || {};

        // Register functions to be called after cascading data loading done
        this.then = function(callback) {
            afterActions.push(callback);
            return this;
        };

        // noinspection JSUnusedLocalSymbols
        parent.select2(select2Options).on("change", function (e) {

            child.prop("disabled", true);
            const _this = this;

            $.getJSON(url.replace(':parentId:', $(this).val()), function(items) {
                let newOptions = '<option value="">-- Select --</option>';
                for(const id in items) {
                    newOptions += '<option value="'+ id +'">'+ items[id] +'</option>';
                }

                child.select2('destroy').html(newOptions).prop("disabled", false)
                    .select2(options);

                afterActions.forEach(function (callback) {
                    callback(parent, child, items);
                });
            });
        });
    }

    return Select2Cascade;

})( window, $);
